import React from "react";
import OwnerCard from "./KeyData";
import img1 from "../asset/Darshit.jpg";
import img2 from "../asset/person.jpg";

const AboutUs = () => {
  const owner1 = {
    name: "Mr. DARSHIT ",
    image: img1,
    bio: "Founder & CEO",
  };

  const owner2 = {
    name: "MR.DEV ",
    image: img2,
    bio: "Co-founder & CTO",
  };

  return (
    <div>
      <OwnerCard owner1={owner1} owner2={owner2} />
    </div>
  );
};

export default AboutUs;
