import React from "react";
// import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";

import logo from "../asset/logo.png";
// import AboutUs from "./AboutUs"; // Import the About Us component

export const Navigation = (props) => {
  const navigate = useNavigate(); // useNavigate hook

  // Function to close the navbar
  const closeNavbar = () => {
    const navbarToggler = document.querySelector(".navbar-toggle");
    if (navbarToggler.classList.contains("collapsed")) return; // Navbar is already collapsed
    navbarToggler.click(); // Click to collapse the navbar
  };

  // Function to handle click on a nav item
  const handleNavItemClick = () => {
    closeNavbar(); // Close the navbar after clicking a nav item
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <Link
              to="/"
              className="navbar-brand page-scroll"
              style={{
                paddingTop: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                style={{
                  maxHeight: "70px",
                  maxWidth: "200px",
                  marginRight: "10px",
                }}
                alt="Logo"
              ></img>
            </Link>{" "}
          </div>
        </div>
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                About <span className="caret"></span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    to="/about-us"
                    className="dropdown-item"
                    onClick={handleNavItemClick} // Close navbar on click
                  >
                    About Us
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/services"
                onClick={handleNavItemClick} // Close navbar on click
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/portfolio"
                onClick={handleNavItemClick} // Close navbar on click
              >
                Gallery
              </Link>
            </li>
            <li>
              <Link
                to="/testimonials"
                onClick={handleNavItemClick} // Close navbar on click
              >
                Testimonials
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                onClick={handleNavItemClick} // Close navbar on click
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
